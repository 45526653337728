import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import loadable from '@loadable/component';
import ReactTooltip from 'react-tooltip';

// Imports => Constants
import {
  ICONS,
  IQIP_ROLES,
  IQIP_ONLY_PERMISSIONS,
  PERMISSION_DESCRIPTIONS,
  PERMISSIONS,
  RESTRICTED_PERMISSIONS,
  TITLES,
  VISUALS,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcFormatRole } from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Atoms
import { AcColumn, AcContainer, AcRow } from '@atoms/ac-grid';
const AcCard = loadable(() => import('@atoms/ac-card/ac-card.web'));
const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web'));
const AcIcon = loadable(() => import('@atoms/ac-icon/ac-icon.web'));
const AcRichContent = loadable(() =>
  import('@atoms/ac-rich-content/ac-rich-content.web')
);
const AcSelectBox = loadable(() =>
  import('@atoms/ac-select-box/ac-select-box.web')
);
const AcDivider = loadable(() => import('@atoms/ac-divider/ac-divider.web'));
const AcEmptyBlock = loadable(() =>
  import('@atoms/ac-empty-block/ac-empty-block.web')
);
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));

const _CLASSES = {
  MAIN: 'ac-user-overview-matrix-tab',
};

const AcUserOverviewMatrixTab = ({ store: { permissions, profile, ui } }) => {
  const $tablehead = useRef(null);
  const $tablebody = useRef(null);

  const { can, cannot } = usePermissions();

  useEffect(async () => {
    await permissions.get_all_permissions().then(async () => {
      await permissions.get_all_roles();
    });
  }, []);

  const handleRemovePermissionFromRole = useCallback((role, permission) => {
    ui.confirm({
      instance: permissions,
      title: TITLES.REMOVE_PERMISSION,
      content: `<p>You are about to unassign permission <strong>${permission?.name.toLowerCase()}</strong> from role <strong>${AcFormatRole(
        role.name
      )}</strong>.</p><p class="h-margin-top-15"><strong>Are you sure you want to proceed?</strong></p>`,
      confirm: {
        label: 'Yes, continue',
        callback: async () => {
          return await permissions
            .remove_permission_from_role(role, permission)
            .then(async () => {
              await profile.who_am_i();
              ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
            })
            .catch(() => {
              ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
            });
        },
      },
    });
  });

  const handleAssignPermissionToRole = useCallback((role, permission) => {
    ui.confirm({
      instance: permissions,
      title: TITLES.ASSIGN_PERMISSION,
      content: `<p>You are about to assign permission <strong>${permission?.name.toLowerCase()}</strong> to role <strong>${AcFormatRole(
        role.name
      )}</strong>.</p><p class="h-margin-top-15"><strong>Are you sure you want to proceed?</strong></p>`,
      confirm: {
        label: 'Yes, continue',
        callback: async () => {
          return await permissions
            .assign_permission_to_role(role, permission)
            .then(async () => {
              await profile.who_am_i();
              ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
            })
            .catch(() => {
              ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
            });
        },
      },
    });
  });

  const groupedPermissions = useMemo(() => {
    if (!AcIsSet(permissions.current_permissions)) return [];

    const collection = permissions.current_permissions;
    const len = collection.length;
    let n = 0;
    let result = {};
    let groups = [];

    for (n; n < len; n++) {
      const item = collection[n];

      const arr = item.name.split('.');
      const group = arr[0];
      const action = arr[1];

      if (AcIsSet(result[group])) {
        result[group].rules.push({ ...item, rule: action.toLowerCase() });
      } else {
        result[group] = {
          id: item.id,
          name: group.toLowerCase(),
          rules: [{ ...item, rule: action.toLowerCase() }],
        };
      }
    }

    return result;
  }, [permissions.current_permissions]);

  const handleMouseEnter = (event, permission, group) => {
    if (!$tablebody.current) return;

    window.requestAnimationFrame(() => {
      const selection = $tablebody?.current?.querySelectorAll(
        `tr[data-permission-id="${permission}"] td`
      );
      const groupcell = $tablebody?.current?.querySelector(
        `tr[data-group="${group}"] td`
      );
      const rows = [...selection, groupcell];

      if (rows) rows.forEach((row) => (row.style.background = '#e5f2fa'));
    });
  };

  const handleMouseExit = (event, permission, group) => {
    if (!$tablebody.current) return;

    window.requestAnimationFrame(() => {
      const selection = $tablebody?.current?.querySelectorAll(
        `tr[data-permission-id="${permission}"] td`
      );
      const groupcell = $tablebody?.current?.querySelector(
        `tr[data-group="${group}"] td`
      );
      const rows = [...selection, groupcell];

      if (rows) rows.forEach((row) => (row.style = null));
    });
  };

  const handleMouseOver = (event, role, permission) => {
    if (!$tablebody.current) return;

    window.requestAnimationFrame(() => {
      const theadcells = $tablehead?.current?.querySelectorAll(
        `th[data-role-id="${role}"]`
      );
      const tbodycells = $tablebody?.current?.querySelectorAll(
        `td[data-role-id="${role}"]`
      );
      const columns = [...theadcells, ...tbodycells];

      if (columns)
        columns.forEach((column) => (column.style.background = '#e5f2fa'));
    });
  };

  const handleMouseLeave = (event, role, permission) => {
    if (!$tablebody.current) return;

    window.requestAnimationFrame(() => {
      const theadcells = $tablehead?.current?.querySelectorAll(
        `th[data-role-id="${role}"]`
      );
      const tbodycells = $tablebody?.current?.querySelectorAll(
        `td[data-role-id="${role}"]`
      );
      const columns = [...theadcells, ...tbodycells];

      if (columns) columns.forEach((column) => (column.style = null));
    });
  };

  const renderTableHead = useMemo(() => {
    if (!AcIsSet(permissions.current_roles)) return null;
    if (!AcIsSet(permissions.current_permissions)) return null;

    const collection = permissions.current_roles;
    const len = collection.length;
    let n = 0;
    let result = [<th colspan={1}>&nbsp;</th>];

    for (n; n < len; n++) {
      const item = collection[n];

      const prettyRole = AcFormatRole(item.name);
      const isIqipRole = IQIP_ROLES.indexOf(item.name) > -1;

      const obj = (
        <th
          key={`matrix-column-role-id-${item.id}`}
          data-role-id={item.id}
          onMouseOver={(event) => handleMouseOver(event, item.id)}
          onMouseLeave={(event) => handleMouseLeave(event, item.id)}
        >
          {prettyRole}
          {isIqipRole && <span rel={'dot'} />}
        </th>
      );

      result.push(obj);
    }

    return <tr>{result}</tr>;
  }, [
    permissions.current_permissions,
    permissions.current_roles,
    permissions.current_role_and_permissions,
  ]);

  const renderPermissionsPerRole = useCallback(
    (permission) => {
      const collection = permissions.current_roles;
      const len = collection.length;
      let n = 0;
      let result = [];

      for (n; n < len; n++) {
        const role = collection[n];

        const hasPermission = role?.permissions?.find(
          (line) => `${line.id}` === `${permission.id}`
        );

        let icon = ICONS.MINUS;

        if (AcIsSet(hasPermission)) {
          icon = ICONS.CHECKBOX_MARKED_CIRCLE_OUTLINE;
        }

        const isRestrictedPermission = RESTRICTED_PERMISSIONS.find((p) => {
          return permission.name.toLowerCase().indexOf(p.toLowerCase()) > -1;
        });
        const isIQIPRole = IQIP_ROLES.indexOf(role.name) > -1;
        const isIQIPPermission = IQIP_ONLY_PERMISSIONS.find((p) => {
          return permission.name.toLowerCase().indexOf(p.toLowerCase()) > -1;
        });

        if (isRestrictedPermission) {
          icon = ICONS.CLOSE_CIRCLE_OUTLINE;
        } else if (isIQIPPermission && !isIQIPRole) {
          icon = ICONS.MINUS_CIRCLE_OUTLINE;
        }

        const actionProps = {};

        let title = `Role: ${AcFormatRole(role.name)}`;

        if (
          !isRestrictedPermission &&
          (!isIQIPPermission || (isIQIPPermission && isIQIPRole))
        ) {
          if (can(PERMISSIONS.PERMISSIONS.MANAGE)) {
            actionProps.onClick = async (event) => {
              if (hasPermission) {
                await handleRemovePermissionFromRole(role, permission);
              } else {
                await handleAssignPermissionToRole(role, permission);
              }
            };
          }
        } else {
          if (isRestrictedPermission)
            title = 'This permission is restricted and cannot be assigned';
          else title = 'This permission is for IQIP roles only';
        }

        const obj = (
          <td
            key={`matrix-row-role-id=${role.id}-permission-id-${permission.id}`}
            data-available={AcIsSet(actionProps?.onClick)}
            data-restricted={isIQIPPermission && !isIQIPRole}
            data-role-id={role.id}
            data-permission-id={permission.id}
            data-has-permission={AcIsSet(hasPermission)}
            onMouseOver={(event) => handleMouseOver(event, role.id)}
            onMouseLeave={(event) => handleMouseLeave(event, role.id)}
            title={title}
          >
            <span {...actionProps}>
              <AcIcon icon={icon} />
            </span>
          </td>
        );

        result.push(obj);
      }

      return result;
    },
    [permissions.current_permissions, permissions.current_roles]
  );

  const renderTableBody = useMemo(() => {
    if (
      !AcIsSet(groupedPermissions) ||
      !Object.keys(groupedPermissions)?.length
    )
      return null;

    const collection = Object.keys(groupedPermissions);
    const len = collection.length;
    let n = 0;
    let result = [];

    const spacer = (
      <tr rel={'matrix-body-spacer'}>
        <td colspan={permissions.current_roles.length + 2} />
      </tr>
    );

    for (n; n < len; n++) {
      const key = collection[n];
      const group = groupedPermissions[key];

      const rules = group.rules;
      const rlen = rules.length;
      let x = 0;
      let lines = [];

      let groupCell = (
        <td
          key={`matrix-column-permission-group-id-${group.id}`}
          data-permission-id={group.id}
          rowspan={rlen}
        >
          <span>{group.name}</span>
        </td>
      );

      for (x; x < rlen; x++) {
        const item = rules[x];

        if (x > 0) groupCell = null;

        const cellsContainingHasPermissionPerRole =
          renderPermissionsPerRole(item);

        const description = PERMISSION_DESCRIPTIONS?.[item.name.toUpperCase()];

        console.log('description', description);

        const ruleCell = (
          <td key={`matrix-column-permission-group-id-${group.id}-${item.id}`}>
            <span>{item.rule}</span>
            <sub
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </td>
        );

        const row = (
          <tr
            key={`matrix-row-permission-id-${item.id}`}
            data-permission-id={item.id}
            data-group={group.name}
            onMouseEnter={(event) =>
              handleMouseEnter(event, item.id, group.name)
            }
            onMouseLeave={(event) =>
              handleMouseExit(event, item.id, group.name)
            }
          >
            {groupCell}
            {ruleCell}
            {cellsContainingHasPermissionPerRole}
          </tr>
        );

        result.push(row);
      }

      if (n < len - 1) result.push(spacer);
    }

    return result;
  }, [
    permissions.current_permissions,
    permissions.current_roles,
    groupedPermissions,
  ]);

  const renderTable = useMemo(() => {
    if (permissions.is_loading) return <AcLoader loading={true} />;
    if (
      !AcIsSet(permissions.current_permissions) ||
      !AcIsSet(permissions.current_roles)
    )
      return (
        <AcEmptyBlock message={'No roles & permissions found to display.'} />
      );

    return (
      <table>
        <thead ref={$tablehead}>{renderTableHead}</thead>
        <tbody ref={$tablebody}>{renderTableBody}</tbody>
      </table>
    );
  }, [
    permissions.current_permissions,
    permissions.current_roles,
    groupedPermissions,
  ]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  }, []);

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn>
            <AcHeading tag={'h2'} rank={5}>
              Roles & Permissions Matrix
            </AcHeading>
            <AcRichContent
              content={'<p>An overview of all roles and permissions</p>'}
            />
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn>
            <AcDivider />
          </AcColumn>
        </AcRow>

        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn>
            <AcCard dense flat className={'h-padding-x-15 h-padding-y-15'}>
              {renderTable}
            </AcCard>
          </AcColumn>
        </AcRow>
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcUserOverviewMatrixTab));
